import { render, staticRenderFns } from "./agent.vue?vue&type=template&id=f0674c22"
import script from "./agent.vue?vue&type=script&lang=js"
export * from "./agent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavbarDarkModeSwitch: require('/app/components/navbar/DarkModeSwitch.vue').default,NavbarNotificationList: require('/app/components/navbar/NotificationList.vue').default,NavbarMessageDropdown: require('/app/components/navbar/MessageDropdown.vue').default,CustomToaster: require('/app/components/CustomToaster.vue').default,LoadingLanguageString: require('/app/components/LoadingLanguageString.vue').default})
