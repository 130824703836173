import { routes } from "./routes-constants"

const NO_PERMISSION = 'You don\'t have permissions'

const pendingRequests = new Map()
const getRequestKey = (config) => `${config.method}:${config.url}`

function accessObject(value) {
	const keys = value.split('.');
	let current = routes;
	for (const key of keys) {
		if (current[key]) {
			current = current[key];
		} else {
			return undefined;
		}
	}
	return current;
}

// Dynamic Routes, path/*
const removeAgentPrefix = [
	'/language-strings/language/',
	'/statistics/'
]

export default function (context) {
	const isAgentWebsite = context.$cookie.get('ia')
	// check the user permissions, if return false, stop sending request to server
	const checkUserPermission = (type, url) => { // type: create, delete, update, view
		// if the user is Super Admin, always return true since Super Admin can do any the system
		if ((context.$cookie?.get('user') && context.$cookie?.get('user').user_type === 1) || !context.$cookie?.get('token')) return true
		// for update user profile
		if (url === '/users') return true
		if (url.includes('users/me/info')) return true
		if (url === '/users/session/logout') return true
		if (type === 'get' && (url === '/users/permissions' || url === '/agents/permissions' || url.includes('/language-strings') || url === '/currency') || url.includes('/languages') || url.includes('/master') || url.includes('csl-master/master')) {
			return true;
		}
		const userPermissions = context.store.state.settings.userPermissions
		const currentPermissionCode = context.store.state.settings.currentPermissionCode
		// getting my user permissions object
		const permissionsByCurrentPermissionCode = userPermissions.find(el => el.code === currentPermissionCode);
		if (!permissionsByCurrentPermissionCode) {
			if (url === '/users/permissions' || url === '/agents/permissions' || url.includes('/language-strings') || url.includes('login') || url.includes('verify-tfa-otp') || url === '/currency' || url === '/users/session/logout' || url === '/users' || url === 'users/me/info') {
				return true;
			}
			return false
		}

		if (type === 'get') return permissionsByCurrentPermissionCode.can_view;
		if (type === 'post') return permissionsByCurrentPermissionCode.can_create
		if (type === 'delete') return permissionsByCurrentPermissionCode.can_delete
		if (type === 'put') return permissionsByCurrentPermissionCode.can_update
	}
	// converting local time to UTC time
	const utcTime = (date) => {
		if (date) {
			return context.$dayjs(date).utc().format('YYYY-MM-DD HH:mm:ss')
		}
		return context.$dayjs().utc().format('YYYY-MM-DD HH:mm:ss')
	}
	context.$axios.onRequest((config) => {
        const requestKey = getRequestKey(config)

        // Create temporary cancel token if not already exists
        if (!config.cancelToken) {
            // Create a new cancel token
            const source = context.$axios.CancelToken.source()
            config.cancelToken = source.token
            pendingRequests.set(requestKey, source)
        }

		// Dynamic routes path for user and agents
		// Getting paths
		const result = accessObject(config.url)
		let routeUrl = null;

		// Check if the route are route
		const checkIfTheUrlIsTheExceptionalRoutes = removeAgentPrefix.find((el) => {
			if (config.url.startsWith(el)) return true;
			return false;
		})
		if (!checkIfTheUrlIsTheExceptionalRoutes) {
			if (isAgentWebsite) {
				// If the website is agent website, then
				// 1. Make agent paths as default path if it's existing,
				// 2. Make user paths as default if agent path is not exist
				// 3. If neither agent and user paths are not exists, use default path as default
				if (!result) {
					// If routes is not exist, then add prefix
					if (config?.url?.startsWith('/')) {
						routeUrl = '/agents' + config.url
					} else {
						routeUrl = '/agents/' + config.url
					}
				} else if (result?.agent) routeUrl = result.agent
				else if (result?.user) routeUrl = result.user
			} else routeUrl = result?.user

			if (routeUrl) config.url = routeUrl
		}

		if (process.client) {
			/**
			 * production mode
			 */
			config.headers.common['agent-domain'] = window?.location?.host
			/**
			 * development mode
			 */
			// config.headers.common['agent-domain'] = 'office.wing365.online'
			// config.headers.common['agent-domain'] = 'office.ddbet99.com'
		}
		const params = { ...config.params } || {}
		// This param is for DateButtonFilters Component purpose only
		if (params.selectedDate) params.selectedDate = null

		// Check if the request have dates
		if (params.start_date) params.start_date = utcTime(params.start_date)
		if (params.end_date) params.end_date = utcTime(params.end_date)
		if (params?.start_month) params.start_date = params.start_month
		if (params?.end_month) params.end_date = params.end_month
		// What is keepCurrency for?
		// Don't use the global currency
		// if (params.currency && !params.keepCurrency) params.currency = context.store.state.master.globalDefaultCurrency
		if (params?.notFire) delete params.notFire


		// To remove null values from parameter
		for (const [key, value] of Object.entries(params)) {
			if (value || value === false) {
				config.params[key] = value;
			} else {
				delete config.params[key]
			}
		}
		if (config?.params?.start_month) delete config.params.start_month
		if (config?.params?.end_month) delete config.params.end_month
		if (params?.keepCurrency) delete config.params.keepCurrency
		if (params?.notFire || params?.notFire === false) delete config.params.notFire
		if (checkUserPermission(config.method, config.url) === false) {
			throw new Error(NO_PERMISSION) // Stop processing the request
		}
		config.baseURL = context.app.$config.API
		config.headers.common.Authorization = `Bearer ${context.$cookie?.get('token') || context.$cookie?.get('agentToken') || context.$cookie?.get('affiliateToken')}`
		if (context.$cookie?.get('currency')) {
			config.headers.common.currency = `${context.$cookie?.get('currency')}`
		}
	})

	// Check if response is in JSON.
	context.$axios.interceptors.response.use(response => {
            const requestKey = getRequestKey(response.config)
            pendingRequests.delete(requestKey)
		const isExist = response.headers['content-type']?.indexOf('application/json');
		return isExist !== -1 ? response : Promise.reject(response);
	}, error => Promise.reject(error));

	// Error handling
	context.$axios.onError((error) => {
		//   Once user Unauthorized then moved them to login page.
		if (error.response?.status === 401) {
            cancelAllRequests()
			if (context.$cookie?.get('token')) {
				context.$cookie.remove('token')
				context.app.router.push('/login')
			} else if (context.$cookie?.get('agentToken') || context.$cookie?.get('affiliateToken')) {
				context.$cookie.remove('agentToken')
				context.$cookie.remove('affiliateToken')
				context.app.router.push('/agent')
			} else if (window.location.pathname.split('/')[1] === 'agent') {
				context.$cookie.remove('agentToken')
				context.$cookie.remove('affiliateToken')
				context.app.router.push('/agent')
			} else {
				context.$cookie.remove('token')
				context.app.router.push('/login')
			}
			// context.$cookie.remove('token')
			// context.app.router.push('/login')
		}

		// Clear old toast before showing the new one
        if (error?.response) {
            context.$toast.clear()
        }

		// Why not putting this toast in the THROW ERROR case, because I need toast.clear() comes after this toast
		if (error?.message === NO_PERMISSION) {
			context.$toast.error(NO_PERMISSION)
			context.store.dispatch('settings/redirectToHasPermissionRoute')
		} else if (error?.response && error?.response?.data?.message) {
			// This try catch is used in case the language is not really loaded
			// And sometimes it gets
			try {
				const languageStrings = context.store.state.master.translationString
				const text = languageStrings[error.response.data.message]

				if (text) {
					context.$toast.error(text)
				} else {
					context.$toast.error(error.response.data.message)
				}
			} catch (error) { }
		} else if (error.message !== '') {
			context.$toast.error('Try again after some time!')
		}
		if (error.response?.status === 403) {
			if (error?.response?.data?.message === 'forbidden_ip_whitelist') {
                // If the user IP is not whitelisted, then just redirect to the no-permissions page
                // In the case of forbidden IP, we don't need to redirect to no-permission page if it's a login page
                if (!error?.request?.responseURL?.includes('/login')) {
				    context.$cookie.remove('agentToken')
                    context.$cookie.remove('token')
                    context.app.router.push('/login')
                    return false
                }
            } else {
                const userData = context.$cookie.get('user') || {}
                userData.user_type = 2
                context.$cookie.set('user', userData)
                context.store.dispatch('settings/redirectToHasPermissionRoute')
            }
		}
		return false
	})
}

const cancelAllRequests = () => {
    pendingRequests.forEach((source) => source.cancel(''))
    pendingRequests.clear()
}
