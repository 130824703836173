
export default {
    name: 'Blank',
    data() {
        return {
            boxSize: 50,
            gridCols: 0,
            gridRows: 0,
        }
    },
    mounted() {
        this.calculateGrid()
        window.addEventListener('resize', this.calculateGrid)
        this.$nextTick(() => this.animateGrid())
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.calculateGrid)
    },

    methods: {
        calculateGrid() {
            this.gridCols = Math.ceil(window.innerWidth / this.boxSize)
            this.gridRows = Math.ceil(window.innerHeight / this.boxSize)
            this.$nextTick(() => this.animateGrid())
        },
        getRandomColor() {
            const colors = [
                '#217dbb',
                '#2980c5',
                '#3498db',
                '#3d9edf',
                '#45a6e8',
                '#4fb0ed',
                '#54b1f0',
                '#66bbf5',
                '#75c4f9',
                '#8fd4ff',
            ]
            return colors[Math.floor(Math.random() * colors.length)]
        },
        animateGrid() {
            const animate = () => {
                const randomRow = Math.floor(Math.random() * this.gridRows)
                const randomCol = Math.floor(Math.random() * this.gridCols)
                const targetId = `#box-${randomRow}-${randomCol}`

                this.$anime({
                    targets: targetId,
                    opacity: [1, 0.2, 1],
                    fill: this.getRandomColor(),
                    duration: 3000, // Slower fade-in and fade-out
                    easing: 'easeInOutQuad',
                    complete: animate, // Loop animation on a new random rect
                })
            }

            animate() // Start the animation loop
        },
    },
}
